import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './pages/login/Login';
import UploadPage from './pages/uploadPage/UploadPage';

function App() {
  const { globalAppState: appState } = useSelector((state): any => state);

  return (
    <div className="App" data-testid="app-test">
      <Routes>
        <Route path="/" element={<UploadPage />} />

        <Route path="/login" element={<Login />} />
      </Routes>
      {!appState.isLoggedIn && <Navigate to="/login" />}
    </div>
  );
}

export default App;
