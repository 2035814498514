/* eslint-disable no-loop-func */
import { SelectChangeEvent } from '@mui/material';
import { AxiosProgressEvent } from 'axios';
import moment from 'moment';
import { ChangeEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { triggerLogout } from '../domain/AppSlice';
import http from '../service';
import AuthService from '../service/authService';

interface UploadPageStateProps {
  open: boolean;
  message: string;
  vertical: 'top';
  horizontal: 'center';
}

export default function useUploadPageData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [snackBarState, setSnackBarState] = useState<UploadPageStateProps>({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open, message } = snackBarState;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedJsonFile, setSelectedJsonFile] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [handleDataCorruptErrorInUpload, setHandleDataCorruptErrorInUpload] =
    useState({
      data: { errors: [], info: [], isCurrDataFlag: false },
      flag: false,
    });

  const [fileCheckError, setFileCheckError] = useState({
    title: '',
    message: '',
    flag: false,
  });
  const [uploadStatus, setUploadStatus] = useState('select');
  const [typeOfUploadFile, setTypeOfUploadFile] = useState('');

  const [apiLoading, setApiLoading] = useState(false);

  const checkDateFormat = (date: string) => {
    return date === '' ? '' : moment(date).format('YYYY-MM-DD');
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFileCheckError({
      title: '',
      message: '',
      flag: false,
    });
    setHandleDataCorruptErrorInUpload({
      data: { errors: [], info: [], isCurrDataFlag: false },
      flag: false,
    });
    setTypeOfUploadFile(event.target.value as string);
  };

  const onChooseFile = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const isVaildEntry = (obj: any, index: number) => {
    let errorIndex = -1;

    Object.keys(obj).forEach((key) => {
      if (key.includes('SOC') && obj[key] !== '') {
        if (
          obj[`${key.split('SOC')[0]}DATE`] !== '' &&
          obj[`${key.split('SOC')[0]}EXPIRY_DAY`] !== ''
        ) {
          if (
            checkDateFormat(obj[`${key.split('SOC')[0]}DATE`]) !==
              'Invalid date' &&
            checkDateFormat(obj[`${key.split('SOC')[0]}EXPIRY_DAY`]) !==
              'Invalid date'
          ) {
            obj[`${key.split('SOC')[0]}DATE`] = checkDateFormat(
              obj[`${key.split('SOC')[0]}DATE`]
            );
            obj[`${key.split('SOC')[0]}EXPIRY_DAY`] = checkDateFormat(
              obj[`${key.split('SOC')[0]}EXPIRY_DAY`]
            );
          } else {
            errorIndex = index + 1;
          }
        } else {
          errorIndex = index + 1;
        }
      }
    });

    return { obj, errorIndex };
  };

  const createProperDownloadableData = (data: any) => {
    let modifiedData = data.map((element: any) => {
      return [...Object.values(element)];
    });
    let headers = [...Object.keys(data[0])];
    modifiedData.unshift(headers);
    return modifiedData;
  };

  const exportToExcel = (succesItems: any, fileName: string) => {
    const downloadabledata: any = createProperDownloadableData(succesItems);
    const worksheet = XLSX.utils.json_to_sheet(downloadabledata);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      `${typeOfUploadFile === 'currentPlan' ? '' : 'ooc_'}current_plan_data`
    );

    XLSX.writeFile(workbook, fileName + '.xlsx');
    setSnackBarState({
      ...snackBarState,
      open: true,
      message: 'Your file has been downloaded',
    });
  };

  const isValidCurrentPlanFile = (obj: any, index: number) => {
    let errorIndex = -1;

    //case 1 : if mandat column is not present

    const mandColVal = Object.keys(obj).filter((elem) => {
      return (
        elem === 'SUBSCRIBER_LIFETIME_ID' ||
        elem === 'MSISDN' ||
        elem === 'MRC_NEW_NO_VAT'
      );
    });

    if (mandColVal.length < 3) {
      return {
        obj: null,
        errorIndex: index,
        msg: 'MSISDN or LIFETIME-ID or MRC_NEW_NO_VAT column is missing',
      };
    }

    //case 2 : if values of mandat column is not present

    if (
      obj['SUBSCRIBER_LIFETIME_ID'] === '' ||
      obj['MSISDN'] === '' ||
      obj['MRC_NEW_NO_VAT'] === '' ||
      (obj['SUBSCRIBER_LIFETIME_ID'] as string).includes('.') ||
      (obj['SUBSCRIBER_LIFETIME_ID'] as string).includes('+')
    ) {
      errorIndex = index + 1;
    }
    delete obj[''];

    return { obj, errorIndex, msg: '' };
  };

  const isValidColumnsPresentForPegaRec = (headers: any[]) => {
    let mandatoryFieldCollection = [
      'DEVICE_PRICE',
      'DATE',
      'EXPIRY_DAY',
      'OCN_TYPE',
      'SOC',
      'REC_TYPE',
      'TYPE',
      'CONTRACTLENGTHTEXT',
      'DATA_INCLUDED_GB',
      'MRC',
      'PLAN_TYPE',
      'DEVICE_PARENTCEC',
      'DEVICE_COLLECTION',
    ];
    if (!headers.includes('CUSTOMER_IDENTIFIER')) {
      return { error: true, data: 'CUSTOMER_IDENTIFIER' };
    } else {
      let tempHeaders = headers;
      tempHeaders.shift(); //removing CUSTOMER_IDENTIFIER as its checked and that should be the first row

      let currOffer = 0;

      let missingColumns: Record<string, string[]> = {};

      for (let elem of tempHeaders) {
        if (!Number.isNaN(Number(elem.split('_')[1]))) {
          if (missingColumns[elem.split('_')[1]]) {
            if (Number(elem.split('_')[1]) !== currOffer) {
              currOffer = Number(elem.split('_')[1]);
            }
          } else {
            missingColumns[elem.split('_')[1]] = [];
            missingColumns[elem.split('_')[1]] = [...mandatoryFieldCollection];

            currOffer = Number(elem.split('_')[1]);
          }

          let slicedElem = elem.split('_').slice(2);

          for (let i = 0; i < missingColumns[elem.split('_')[1]].length; i++) {
            if (
              missingColumns[elem.split('_')[1]][i].includes(
                slicedElem.join('_')
              )
            ) {
              missingColumns[elem.split('_')[1]].splice(i, 1);
              break;
            }
          }
        }
      }

      const result: string[] = [];

      for (let offerNumber in missingColumns) {
        if (missingColumns[offerNumber].length > 0) {
          result.push(
            ...missingColumns[offerNumber].map((offerType: string) => {
              return `OFFER_${offerNumber}_${offerType}`;
            })
          );
        }
      }

      return result.length < 1
        ? { error: false, data: '' }
        : { error: true, data: result.join(' , ') };
    }
  };

  const isValidOOCCurrentPlanFile = (obj: any, index: number) => {
    let errorIndex = -1;

    //case 1 : if mandat column is not present

    const mandColVal = Object.keys(obj).filter((elem) => {
      return (
        elem === 'SUBSCRIBER_LIFETIME_ID' ||
        elem === 'MSISDN' ||
        elem === 'PRODUCT_NAME' ||
        elem === 'Plan_End_Date'
      );
    });

    if (mandColVal.length < 4) {
      return {
        obj: null,
        errorIndex: index,
        msg: 'MSISDN or LIFETIME-ID or PRODUCT_NAME or Plan_End_Date column is missing',
      };
    }

    //case 2 : if values of mandat column is not present

    if (
      obj['SUBSCRIBER_LIFETIME_ID'] === '' ||
      obj['MSISDN'] === '' ||
      obj['PRODUCT_NAME'] === '' ||
      obj['Plan_End_Date'] === '' ||
      (obj['SUBSCRIBER_LIFETIME_ID'] as string).includes('.') ||
      (obj['SUBSCRIBER_LIFETIME_ID'] as string).includes('+')
    ) {
      errorIndex = index + 1;
    }
    delete obj[''];

    return { obj, errorIndex, msg: '' };
  };

  const handleConvert = async (file: Blob) => {
    setFileCheckError({
      title: '',
      message: '',
      flag: false,
    });
    const workbook = XLSX.read(await file.arrayBuffer(), { type: 'buffer' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: '',
      raw: false,
    });

    if (json.length === 0) {
      setFileCheckError({
        title: 'Please do not upload an empty file',
        message: '',
        flag: true,
      });
      setSelectedFile(null);
      setSelectedJsonFile(null);
    } else {
      //checking if the type of file using the header row
      const headers: any = json[0];
      if (
        headers[0] === 'SUBSCRIBER_LIFETIME_ID' &&
        typeOfUploadFile === 'currentPlan'
      ) {
        // Removed the headers row
        json.shift();

        let rowCount: Number[] = [];
        let mandtColMsg = '';

        const jsonData = json
          ?.map((row: any, index: number) => {
            const isEmptyRow = row.filter(Boolean).length === 0;
            if (isEmptyRow) {
              return null;
            }
            const obj: any = {};
            headers.forEach((header: any, index: any) => {
              obj[header] = row[index];
            });
            let validObject = isValidCurrentPlanFile(obj, index);
            if (validObject.msg !== '') {
              mandtColMsg = validObject.msg;
              return null;
            }
            if (validObject.errorIndex !== -1) {
              rowCount.push(validObject.errorIndex);
            }
            return validObject.obj;
          })
          .filter(Boolean);

        if (rowCount.length > 0) {
          setFileCheckError({
            title: '',

            message: `MSISDN or LIFETIME-ID or MRC_NEW_NO_VAT is missing, or LIFETIME-ID has character like "+" or ".", it should be a number. These exceptions are for row/rows: ${rowCount.join(
              ', '
            )}`,
            flag: true,
          });
          setSelectedFile(null);
          setSelectedJsonFile(null);
        } else if (mandtColMsg !== '') {
          setFileCheckError({
            title: '',
            message: mandtColMsg,
            flag: true,
          });
          mandtColMsg = '';
          setSelectedFile(null);
          setSelectedJsonFile(null);
        } else {
          setFileCheckError({
            title: '',
            message: '',
            flag: false,
          });
          setSelectedFile(file as File);
          setSelectedJsonFile(jsonData);
        }
      } else if (
        headers[0] === 'SUBSCRIBER_LIFETIME_ID' &&
        typeOfUploadFile === 'oocCurrentPlans'
      ) {
        // Removed the headers row
        json.shift();

        let rowCount: Number[] = [];
        let mandtColMsg = '';

        const jsonData = json
          ?.map((row: any, index: number) => {
            const isEmptyRow = row.filter(Boolean).length === 0;
            if (isEmptyRow) {
              return null;
            }
            const obj: any = {};
            headers.forEach((header: any, index: any) => {
              obj[header] = row[index];
            });
            let validObject = isValidOOCCurrentPlanFile(obj, index);
            if (validObject.msg !== '') {
              mandtColMsg = validObject.msg;
              return null;
            }
            if (validObject.errorIndex !== -1) {
              rowCount.push(validObject.errorIndex);
            }
            return validObject.obj;
          })
          .filter(Boolean);

        if (rowCount.length > 0) {
          setFileCheckError({
            title: '',

            message: `MSISDN or LIFETIME-ID or PRODUCT_NAME or Plan_End_Date is missing, or LIFETIME-ID has character like "+" or ".", it should be a number. These exceptions are for row/rows: ${rowCount.join(
              ', '
            )}`,
            flag: true,
          });
          setSelectedFile(null);
          setSelectedJsonFile(null);
        } else if (mandtColMsg !== '') {
          setFileCheckError({
            title: '',
            message: mandtColMsg,
            flag: true,
          });
          mandtColMsg = '';
          setSelectedFile(null);
          setSelectedJsonFile(null);
        } else {
          setFileCheckError({
            title: '',
            message: '',
            flag: false,
          });
          setSelectedFile(file as File);
          setSelectedJsonFile(jsonData);
        }
      } else if (
        headers[0] === 'CUSTOMER_IDENTIFIER' &&
        typeOfUploadFile === 'PegaRecommendations'
      ) {
        let firstRowOfHeaders = headers[0];
        let isCorruptData = isValidColumnsPresentForPegaRec(headers);
        headers.unshift(firstRowOfHeaders);

        if (isCorruptData.error) {
          setFileCheckError({
            title:
              'Please do not upload corrupt Pega recommendations data as you are missing some mandatory columns/column like:',
            message: `${isCorruptData.data}`,
            flag: true,
          });
          setSelectedFile(null);
          setSelectedJsonFile(null);
        } else {
          // Removed the headers row
          json?.shift();

          let rowCount: Number[] = [];
          // Transform the data into an array of objects
          const jsonData = json?.map((row: any, index: number) => {
            const obj: any = {};
            headers.forEach((header: any, index: any) => {
              obj[header] = row[index];
            });
            let validObject = isVaildEntry(obj, index);
            if (validObject.errorIndex !== -1) {
              rowCount.push(validObject.errorIndex);
            }
            return validObject.obj;
          });

          if (rowCount.length > 0) {
            setFileCheckError({
              title:
                'Please select a xlsx file with proper date (YYYY-MM-DD) format for row/rows: ',

              message: `${rowCount.join(', ')}`,
              flag: true,
            });
            setSelectedFile(null);
            setSelectedJsonFile(null);
          } else {
            setFileCheckError({
              title: '',
              message: '',
              flag: false,
            });
            setSelectedFile(file as File);
            setSelectedJsonFile(jsonData);
          }
        }
      } else {
        setFileCheckError({
          title: 'Please select a valid file for the option selected',

          message: '',

          flag: true,
        });
      }
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].name.split('.xlsx').length !== 2) {
        setFileCheckError({
          title: 'Please select a xlsx file only',
          message: '',
          flag: true,
        });
      } else {
        handleConvert(event.target.files[0]);
      }
    }
  };

  const clearFileInput = () => {
    if (inputRef.current) inputRef.current.value = '';
    setSelectedFile(null);
    setSelectedJsonFile(null);
    setProgress(0);
    setUploadStatus('select');
  };

  const handleClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const getFileName = () => {
    return `${
      typeOfUploadFile === 'currentPlan' ? '' : 'ooc_'
    }curr_plan_for_${new Date().toLocaleDateString()}`;
  };

  const handleUpload = async () => {
    if (!selectedJsonFile?.length) {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message: "Selected file is empty or doesn't have any valid row",
      });
      return;
    }
    if (uploadStatus === 'done') {
      clearFileInput();
      setHandleDataCorruptErrorInUpload({
        data: { errors: [], info: [], isCurrDataFlag: false },
        flag: false,
      });
      return;
    }

    try {
      setUploadStatus('uploading');
      setApiLoading(true);

      const response = await http.post(
        `/v1/${
          typeOfUploadFile === 'currentPlan'
            ? 'account/current-plans'
            : typeOfUploadFile === 'oocCurrentPlans'
            ? 'simo-plans'
            : 'recommended-plans'
        }`,
        selectedJsonFile,
        {
          headers: {
            Authorization: `Bearer ${AuthService.getToken()}`,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const percentCompleted = Math.round(
              // @ts-ignore
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentCompleted);
          },
        }
      );

      setApiLoading(false);

      if (response.status === 401) {
        AuthService.logout();
        dispatch(triggerLogout());
        navigate('/login');
      } else if (response.status === 201) {
        setUploadStatus('done');
        setSnackBarState({
          ...snackBarState,
          open: true,
          message: 'File was Uploaded',
        });
        if (
          typeOfUploadFile === 'currentPlan' ||
          typeOfUploadFile === 'oocCurrentPlans'
        ) {
          if (response.data.data.validationErrors.length > 0) {
            let data = { errors: [], info: [], isCurrDataFlag: true };

            let trimmedErrors = response.data.data.validationErrors.map(
              (elem: any, index: number) => {
                return {
                  message: `${elem.error || 'Validation error on'}: Row ${
                    elem.index + 1
                  }`,
                  plans: elem.plan,
                };
              }
            );

            data = { ...data, errors: trimmedErrors };
            setHandleDataCorruptErrorInUpload({ data, flag: true });
            if (response.data.data.successItems?.length > 0) {
              exportToExcel(response.data.data.successItems, getFileName());
            }
          } else {
            exportToExcel(response.data.data.successItems, getFileName());
          }
        } else {
          if (
            response.data.data.errors.length > 0 ||
            response.data.data.info.length > 0
          ) {
            let data = { errors: [], info: [], isCurrDataFlag: false };

            data = {
              ...data,
              errors: response.data.data.errors,
              info: response.data.data.info,
            };
            setHandleDataCorruptErrorInUpload({ data, flag: true });
          }
        }
      } else if (response.status === 413) {
        setSnackBarState({
          ...snackBarState,
          open: true,
          message: 'Payload Size Exceeded!!',
        });
      } else {
        setSnackBarState({
          ...snackBarState,
          open: true,
          message: response.data?.error.message || 'Something went wrong',
        });
        setUploadStatus('select');

        return;
      }
    } catch (error) {
      setSnackBarState({
        ...snackBarState,
        open: true,
        message: 'File Upload Fail',
      });
      setUploadStatus('select');
      setApiLoading(false);
    }
  };

  return {
    onChooseFile,
    handleFileChange,
    handleClose,
    handleSelectChange,
    inputRef,
    clearFileInput,
    vertical,
    horizontal,
    open,
    handleUpload,
    message,
    selectedFile,
    progress,
    handleDataCorruptErrorInUpload,
    fileCheckError,
    uploadStatus,
    typeOfUploadFile,
    apiLoading,
  };
}
