import { createSlice } from '@reduxjs/toolkit';
import AuthService from '../service/authService';
import { RootState } from '../store/store';

export interface AppState {
  user: {};
  isLoggedIn: boolean;
}

const initialState: AppState = {
  user: {},
  isLoggedIn: AuthService.getToken() ? true : false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    triggerLogin: (state) => {
      state.isLoggedIn = true;
    },
    triggerLogout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const appSliceReducer = appSlice.reducer;
export const { triggerLogin, triggerLogout } = appSlice.actions;
export const globalSelector = (state: RootState) => state.globalAppState;
