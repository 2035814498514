import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch } from 'react-redux';
import { triggerLogout } from '../../domain/AppSlice';
import useUploadPageData from '../../hooks/use-uploadfile-data';
import AuthService from '../../service/authService';
import Accordian from './Accordian';
import './style.css';

const getCloseBtnForSnacbar = (handleClose: () => void) => (
  <IconButton
    size="small"
    aria-label="close"
    color="inherit"
    onClick={handleClose}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

const UploadPage = () => {
  const dispatch = useDispatch();
  const {
    onChooseFile,
    handleFileChange,
    handleClose,
    handleSelectChange,
    inputRef,
    clearFileInput,
    vertical,
    horizontal,
    open,
    handleUpload,
    message,
    selectedFile,
    progress,
    handleDataCorruptErrorInUpload,
    fileCheckError,
    uploadStatus,
    typeOfUploadFile,
    apiLoading,
  } = useUploadPageData();

  return (
    <>
      <div className="upload-page-wrapper">
        <div className="header">
          <div className="logo-container">
            <img src="/assets/ee-logo.png" alt="ee-logo" height={50} />
          </div>

          <span
            className="logout-container"
            onClick={() => {
              AuthService.logout();
              dispatch(triggerLogout());
            }}
          >
            Logout
          </span>
        </div>

        <div className="upload-portal-container">
          <div className="container-file-upload">
            <div className="select-type-of-file-container">
              <FormControl fullWidth={true}>
                {typeOfUploadFile === '' && (
                  <InputLabel>Please select a file...</InputLabel>
                )}
                <Select
                  variant="standard"
                  value={typeOfUploadFile}
                  label="Select Plan"
                  onChange={handleSelectChange}
                  placeholder="hey"
                >
                  <MenuItem value={'currentPlan'}>Current Plans</MenuItem>
                  <MenuItem value={'PegaRecommendations'}>
                    Pega recommendations
                  </MenuItem>
                  <MenuItem value={'oocCurrentPlans'}>
                    OOC campaign current plans
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <input
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              accept=".xlsx"
              multiple={false}
              disabled={typeOfUploadFile === '' ? true : false}
            />
            {!selectedFile && (
              <button
                className="file-btn"
                style={{
                  cursor: typeOfUploadFile === '' ? 'not-allowed' : 'pointer',
                }}
                onClick={onChooseFile}
              >
                <span className="material-symbols-outlined">
                  <img
                    src="/assets/upload.svg"
                    alt="upload"
                    height={20}
                    width={20}
                  />
                </span>{' '}
                Upload reccomended excel file
              </button>
            )}

            {fileCheckError && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '75%',
                }}
              >
                <pre
                  style={{
                    fontSize: '18px',
                    color: 'rgb(84, 12, 181)',
                    fontWeight: '800',
                  }}
                >
                  {fileCheckError.title}
                </pre>
                <pre
                  style={{
                    color: 'red',
                    fontSize: '15px',
                    fontWeight: '900',
                    whiteSpace: 'break-spaces',
                  }}
                >
                  {fileCheckError.message}
                </pre>
              </div>
            )}

            {selectedFile && (
              <>
                <div className="file-card">
                  {/* Space to add some title */}

                  <div className="file-info">
                    <div style={{ flex: 1 }}>
                      <h6>{selectedFile?.name}</h6>

                      <div className="progress-bg">
                        <div
                          className="progress"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                    </div>

                    {uploadStatus === 'select' ? (
                      <button onClick={clearFileInput}>
                        <span className="material-symbols-outlined close-icon">
                          X
                        </span>
                      </button>
                    ) : (
                      <div className="check-circle">
                        {uploadStatus === 'uploading' ? (
                          `${progress}%`
                        ) : uploadStatus === 'done' ? (
                          <span
                            className="material-symbols-outlined close-icon"
                            style={{ fontSize: '20px' }}
                            onClick={clearFileInput}
                          >
                            X
                          </span>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="upload-btn"
                  style={{
                    backgroundColor:
                      uploadStatus === 'select' || uploadStatus === 'uploading'
                        ? 'purple'
                        : 'green',
                  }}
                  onClick={handleUpload}
                  disabled={apiLoading}
                >
                  {uploadStatus === 'select' || uploadStatus === 'uploading'
                    ? `${apiLoading ? 'Uploading...' : 'Ready to Upload'}`
                    : 'Done'}
                </button>
              </>
            )}
          </div>

          {handleDataCorruptErrorInUpload.flag && (
            <div className="accordian-container">
              <>
                <Accordian
                  data={
                    handleDataCorruptErrorInUpload.data ?? {
                      errors: [],
                      info: [],
                      isCurrDataFlag:
                        typeOfUploadFile === 'currentPlan' ||
                        typeOfUploadFile === 'oocCurrentPlans'
                          ? true
                          : false,
                    }
                  }
                />
              </>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        autoHideDuration={3 * 1000}
        action={getCloseBtnForSnacbar(handleClose)}
      />
    </>
  );
};
export default UploadPage;
