import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appSliceReducer } from "../domain/AppSlice";

const combinedReducer = combineReducers({
  globalAppState: appSliceReducer,
});

export const store = configureStore({
  reducer: combinedReducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
