import Cookies from 'js-cookie';
import http from './index';

const login = async (email: string, password: string) => {
  try {
    const loginApiEndPoint = `/v1/auth/admin/login`;
    const response = await http.post(loginApiEndPoint, {
      email,
      password,
    });

    if (!response) {
      return 'Something went wrong!';
    } else {
      if (
        response.data.statusCode === 401 ||
        response.data.statusCode === 400
      ) {
        return 'Invalid Creditials';
      } else if (response.data.statusCode === 200) {
        const expiryDateTime = new Date();
        expiryDateTime.setTime(expiryDateTime.getTime() + 30 * 60 * 1000); // 30mins expiry
        Cookies.set('__eemobile_auth_token', response.data.data.access_token, {
          expires: expiryDateTime,
          secure: true,
        });
        return 'Login Sucessful';
      } else {
        return 'There is some error from our side! Please try again later.';
      }
    }
  } catch (e) {
    console.error(e);
  }
};

const logout = async () => {
  Cookies.remove('__eemobile_auth_token');
};

const getToken = () => {
  return Cookies.get('__eemobile_auth_token');
};

const AuthService = {
  login,
  logout,
  getToken,
};

export default AuthService;
