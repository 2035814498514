/* eslint-disable */
import {
  Box,
  Button,
  FormHelperText,
  Unstable_Grid2 as Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { triggerLogin } from '../../domain/AppSlice';
import AuthService from '../../service/authService';

const Login = () => {
  const [loginResMessage, setLoginResMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid BT email')
        .required('A BT Email is required for Login')
        .matches(/\b@bt.com$/gm, 'Your Email Must Contain @bt.com in the end')
        .typeError(''),
      password: Yup.string()
        .required('Please Enter your password')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
    }),
    onSubmit: async ({ email, password, ...others }, helpers) => {
      try {
        const loginRes = await AuthService.login(email, password);

        if (loginRes === 'Login Sucessful') {
          dispatch(triggerLogin());
          navigate('/');
        } else {
          setLoginResMessage(loginRes ?? '');
        }
      } catch (e) {
        console.error(e);
      }
    },
  });

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 auto',
        }}
      >
        <Grid container sx={{ flex: '1 1 auto' }}>
          <Grid
            xs={12}
            lg={6}
            sx={{
              backgroundColor: 'background.paper',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                backgroundColor: 'background.paper',
                alignItems: 'center',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '60%',
                  px: 3,
                  py: '100px',
                }}
              >
                <div>
                  <Stack spacing={1} sx={{ mb: 3 }}>
                    <Typography variant="h4" sx={{ alignItems: 'center' }}>
                      Login
                    </Typography>
                  </Stack>

                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Stack spacing={3}>
                      <TextField
                        error={!!(formik.touched.email && formik.errors.email)}
                        fullWidth
                        helperText={formik.touched.email && formik.errors.email}
                        label="Email Address"
                        name="email"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="email"
                        value={formik.values.email}
                      />
                      <TextField
                        error={
                          !!(formik.touched.password && formik.errors.password)
                        }
                        fullWidth
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                        label="Password"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="password"
                        value={formik.values.password}
                      />
                    </Stack>
                    <FormHelperText sx={{ mt: 1, color: 'red' }}>
                      {loginResMessage}
                    </FormHelperText>
                    {formik.errors.submit && (
                      <Typography color="error" sx={{ mt: 3 }} variant="body2">
                        {formik.errors.submit}
                      </Typography>
                    )}
                    <Button
                      fullWidth
                      size="large"
                      sx={{ mt: 3, backgroundColor: 'purple' }}
                      type="submit"
                      variant="contained"
                    >
                      Login
                    </Button>
                  </form>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              alignItems: 'center',
              background:
                'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              '& img': {
                maxWidth: '100%',
              },
            }}
          >
            <Box sx={{ p: 3 }}>
              <Typography
                align="center"
                color="inherit"
                sx={{
                  lineHeight: '32px',
                  mb: 1,
                }}
                variant="h1"
              >
                <Box
                  component="a"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  target="_blank"
                >
                  <img src="/assets/ee-logo.png" alt="ee-logo" />
                  <Typography
                    align="center"
                    color="inherit"
                    sx={{
                      fontSize: '24px',
                      lineHeight: '32px',
                      mb: 1,
                    }}
                    variant="h4"
                  >
                    Welcome to Admin Panel
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Login;
