import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['x-api-key'] =
  process.env.REACT_APP_EE_MOBILE_X_API_KEY;

axios.interceptors.response.use(
  (response) => {
    return response;
    // Do something with response data  return response;
  },
  function (error) {
    // Do something with response error  return Promise.reject(error);
    return error.response;
  }
);

export default axios;
