/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

interface AccordianPropsData {
  errors: any[];
  info: any[];
  isCurrDataFlag: boolean;
}

interface AccordianProps {
  data: AccordianPropsData;
}

const Accordion = (data: AccordianProps) => {
  const { errors, info, isCurrDataFlag } = data.data;

  const [expandedIndexError, setExpandedIndexError] = useState(null);
  const [expandedIndexInfo, setExpandedIndexInfo] = useState(null);

  const handleErrorToggleAccordion = (index: any) => {
    if (expandedIndexError === index) {
      setExpandedIndexError(null);
    } else {
      setExpandedIndexError(index);
    }
  };
  const handleInfoToggleAccordion = (index: any) => {
    if (expandedIndexInfo === index) {
      setExpandedIndexInfo(null);
    } else {
      setExpandedIndexInfo(index);
    }
  };
  return (
    <div
      style={{
        padding: '1rem',
        margin: 'auto',
        backgroundColor: '#242424',
        color: 'rgba(255, 255, 255, 0.87)',
        fontFamily: 'Inter, system-ui, Avenir, Helvetica, Arial, sans-serif',
        lineHeight: '1.5',
        fontWeight: '400',
        fontSynthesis: 'none',
        textRendering: 'optimizeLegibility',
        display: 'flex',
        gap: '1rem',
      }}
      data-testid="accordian-test"
    >
      {errors.length > 0 && (
        <div style={{ flex: 1 }}>
          <h3>Errors</h3>
          {errors.map((item, index) => (
            <div key={index}>
              <div onClick={() => handleErrorToggleAccordion(index)}>
                {expandedIndexError === index ? '▼' : '►'}{' '}
                <code style={{ color: '#F88379', cursor: 'pointer' }}>
                  {item.message}
                </code>
              </div>

              {expandedIndexError === index && (
                <ul>
                  {Object.keys(isCurrDataFlag ? item.plans : item).map(
                    (key, index) => (
                      <li key={index}>
                        <code>{key}</code> :
                        {isCurrDataFlag ? item.plans[key] : item[key]}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
      {info.length > 0 && (
        <div style={{ flex: 1 }}>
          <h3>Note:</h3>
          {info.map((item, index) => (
            <div key={index}>
              <div onClick={() => handleInfoToggleAccordion(index)}>
                {expandedIndexInfo === index ? '▼' : '►'}{' '}
                <code style={{ color: '#EEDC82', cursor: 'pointer' }}>
                  {item.message}
                </code>
              </div>
              {expandedIndexInfo === index && (
                <ul>
                  {Object.keys(item).map((key) => (
                    <li>
                      <code>{key}</code>: {item[key]}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
